body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 12px;
  font-family: Roboto;
  background: #fff;
}
.iconfont {
  font-size: 24px;
  font-weight: 400;
  color: #4a4a4a;
}
.icon-down.active {
  transform: rotate(180deg);
}
.iconfont.icon-tips-default {
  opacity: 0.5;
  margin: 0 3px;
}
.iconfont.icon-convert.w {
  color: #fff;
  margin: 0 5px;
}
.navIcon,
.mlangIcon {
  display: none;
}
@media screen and (min-width: 760px) {
  .iconfont.icon-setting,
  .iconfont.icon-plus {
    font-size: 30px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 760px) {
  .container {
    padding-top: 0;
    background-size: contain;
  }
  .step {
    display: none;
  }
  .am-popover-arrow {
    background-color: #333;
  }
  .am-popover-inner-wrapper {
    color: #fff;
    background-color: #333;
  }
  .navIcon,
  .mlangIcon {
    display: block;
    width: 20px;
    height: 20px;
  }
  .iconTips {
    display: none;
  }
  .iconfont.icon-plus {
    color: #ff4311;
    margin: 0 3px;
  }
}

.am-popover {
  position: absolute;
  z-index: 1999;
}
.am-popover-hidden {
  display: none;
}
.am-popover-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  z-index: 999;
}
.am-popover-mask-hidden {
  display: none;
}
.am-popover-arrow {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 1PX;
  background-color: #fff;
  transform: rotate(45deg);
  z-index: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.21);
}
.am-popover-placement-top .am-popover-arrow,
.am-popover-placement-topLeft .am-popover-arrow,
.am-popover-placement-topRight .am-popover-arrow {
  transform: rotate(225deg);
  bottom: -3.5px;
}
.am-popover-placement-top .am-popover-arrow {
  left: 50%;
}
.am-popover-placement-topLeft .am-popover-arrow {
  left: 8px;
}
.am-popover-placement-topRight .am-popover-arrow {
  right: 8px;
}
.am-popover-placement-right .am-popover-arrow,
.am-popover-placement-rightTop .am-popover-arrow,
.am-popover-placement-rightBottom .am-popover-arrow {
  transform: rotate(-45deg);
  left: -3.5px;
}
.am-popover-placement-right .am-popover-arrow {
  top: 50%;
}
.am-popover-placement-rightTop .am-popover-arrow {
  top: 8px;
}
.am-popover-placement-rightBottom .am-popover-arrow {
  bottom: 8px;
}
.am-popover-placement-left .am-popover-arrow,
.am-popover-placement-leftTop .am-popover-arrow,
.am-popover-placement-leftBottom .am-popover-arrow {
  transform: rotate(135deg);
  right: -3.5px;
}
.am-popover-placement-left .am-popover-arrow {
  top: 50%;
}
.am-popover-placement-leftTop .am-popover-arrow {
  top: 8px;
}
.am-popover-placement-leftBottom .am-popover-arrow {
  bottom: 8px;
}
.am-popover-placement-bottom .am-popover-arrow,
.am-popover-placement-bottomLeft .am-popover-arrow,
.am-popover-placement-bottomRight .am-popover-arrow {
  top: -3.5px;
}
.am-popover-placement-bottom .am-popover-arrow {
  left: 50%;
}
.am-popover-placement-bottomLeft .am-popover-arrow {
  left: 8px;
}
.am-popover-placement-bottomRight .am-popover-arrow {
  right: 8px;
}
.am-popover-inner {
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.21);
  overflow: hidden;
}
.am-popover-inner-wrapper {
  position: relative;
  background-color: #fff;
}
.am-popover .am-popover-item {
  padding: 0 8px;
}
.am-popover .am-popover-item-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 39px;
  box-sizing: border-box;
  padding: 0 8px;
}
.am-popover .am-popover-item:not(:first-child) .am-popover-item-container {
  border-top: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-popover .am-popover-item:not(:first-child) .am-popover-item-container {
    border-top: none;
  }
  html:not([data-scale]) .am-popover .am-popover-item:not(:first-child) .am-popover-item-container::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    transform-origin: 50% 50%;
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-popover .am-popover-item:not(:first-child) .am-popover-item-container::before {
    transform: scaleY(0.33);
  }
}
.am-popover .am-popover-item.am-popover-item-active .am-popover-item-container {
  border-top: 0;
}
.am-popover .am-popover-item.am-popover-item-active .am-popover-item-container:before {
  display: none !important;
}
.am-popover .am-popover-item.am-popover-item-active + .am-popover-item .am-popover-item-container {
  border-top: 0;
}
.am-popover .am-popover-item.am-popover-item-active + .am-popover-item .am-popover-item-container:before {
  display: none !important;
}
.am-popover .am-popover-item.am-popover-item-active {
  background-color: #ddd;
}
.am-popover .am-popover-item.am-popover-item-active.am-popover-item-fix-active-arrow {
  position: relative;
}
.am-popover .am-popover-item.am-popover-item-disabled {
  color: #bbb;
}
.am-popover .am-popover-item.am-popover-item-disabled.am-popover-item-active {
  background-color: transparent;
}
.am-popover .am-popover-item-icon {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}

.am-list-header {
  padding: 15px 15px 9px 15px;
  font-size: 14px;
  color: #888;
  width: 100%;
  box-sizing: border-box;
}
.am-list-footer {
  padding: 9px 15px 15px 15px;
  font-size: 14px;
  color: #888;
}
.am-list-body {
  position: relative;
  background-color: #fff;
  border-top: 1PX solid #ddd;
  border-bottom: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-list-body {
    border-top: none;
  }
  html:not([data-scale]) .am-list-body::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    transform-origin: 50% 50%;
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-list-body::before {
    transform: scaleY(0.33);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-list-body {
    border-bottom: none;
  }
  html:not([data-scale]) .am-list-body::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    transform-origin: 50% 100%;
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-list-body::after {
    transform: scaleY(0.33);
  }
}
.am-list-body div:not(:last-child) .am-list-line {
  border-bottom: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-list-body div:not(:last-child) .am-list-line {
    border-bottom: none;
  }
  html:not([data-scale]) .am-list-body div:not(:last-child) .am-list-line::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    transform-origin: 50% 100%;
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-list-body div:not(:last-child) .am-list-line::after {
    transform: scaleY(0.33);
  }
}
.am-list-item {
  position: relative;
  display: flex;
  padding-left: 15px;
  min-height: 44px;
  background-color: #fff;
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 200ms;
  align-items: center;
  /* list左图片显示*/
}
.am-list-item .am-list-ripple {
  position: absolute;
  background: transparent;
  display: inline-block;
  overflow: hidden;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  border-radius: 100%;
  transform: scale(0);
}
.am-list-item .am-list-ripple.am-list-ripple-animate {
  background-color: rgba(158, 158, 158, 0.2);
  animation: ripple 1s linear;
}
.am-list-item.am-list-item-top .am-list-line {
  align-items: flex-start;
}
.am-list-item.am-list-item-top .am-list-line .am-list-arrow {
  margin-top: 2px;
}
.am-list-item.am-list-item-middle .am-list-line {
  align-items: center;
}
.am-list-item.am-list-item-bottom .am-list-line {
  align-items: flex-end;
}
.am-list-item.am-list-item-error .am-list-line .am-list-extra {
  color: #f50;
}
.am-list-item.am-list-item-error .am-list-line .am-list-extra .am-list-brief {
  color: #f50;
}
.am-list-item.am-list-item-active {
  background-color: #ddd;
}
.am-list-item.am-list-item-disabled .am-list-line .am-list-content,
.am-list-item.am-list-item-disabled .am-list-line .am-list-extra {
  color: #bbb;
}
.am-list-item img {
  width: 22px;
  height: 22px;
  vertical-align: middle;
}
.am-list-item .am-list-thumb:first-child {
  margin-right: 15px;
}
.am-list-item .am-list-thumb:last-child {
  margin-left: 8px;
}
.am-list-item .am-list-line {
  position: relative;
  display: flex;
  flex: 1 1;
  align-self: stretch;
  padding-right: 15px;
  overflow: hidden;
  /* list左侧主内容*/
  /* list右补充内容*/
  /* 辅助性文字*/
  /* list右侧箭头*/
}
.am-list-item .am-list-line .am-list-content {
  flex: 1 1;
  color: #000;
  font-size: 17px;
  line-height: 1.5;
  text-align: left;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 7px;
  padding-bottom: 7px;
}
.am-list-item .am-list-line .am-list-extra {
  flex-basis: 36%;
  color: #888;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 7px;
  padding-bottom: 7px;
}
.am-list-item .am-list-line .am-list-title {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.am-list-item .am-list-line .am-list-brief {
  color: #888;
  font-size: 15px;
  line-height: 1.5;
  margin-top: 6px;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.am-list-item .am-list-line .am-list-arrow {
  display: block;
  width: 15px;
  height: 15px;
  margin-left: 8px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2216%22%20height%3D%2226%22%20viewBox%3D%220%200%2016%2026%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%20id%3D%22UI-KIT_%E5%9F%BA%E7%A1%80%E5%85%83%E4%BB%B6%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%229.9%E5%9F%BA%E7%A1%80%E5%85%83%E4%BB%B6%22%20transform%3D%22translate(-5809.000000%2C%20-8482.000000)%22%20fill%3D%22%23C7C7CC%22%3E%3Cpolygon%20id%3D%22Disclosure-Indicator%22%20points%3D%225811%208482%205809%208484%205820.5%208495%205809%208506%205811%208508%205825%208495%22%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  visibility: hidden;
}
.am-list-item .am-list-line .am-list-arrow-horizontal {
  visibility: visible;
}
.am-list-item .am-list-line .am-list-arrow-vertical {
  visibility: visible;
  transform: rotate(90deg);
}
.am-list-item .am-list-line .am-list-arrow-vertical-up {
  visibility: visible;
  transform: rotate(270deg);
}
.am-list-item .am-list-line-multiple {
  padding: 12.5px 15px 12.5px 0;
}
.am-list-item .am-list-line-multiple .am-list-content {
  padding-top: 0;
  padding-bottom: 0;
}
.am-list-item .am-list-line-multiple .am-list-extra {
  padding-top: 0;
  padding-bottom: 0;
}
.am-list-item .am-list-line-wrap .am-list-content {
  white-space: normal;
}
.am-list-item .am-list-line-wrap .am-list-extra {
  white-space: normal;
}
.am-list-item select {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  font-size: 17px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
}
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

.am-picker-popup {
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
}
.am-picker-popup-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  transform: translateZ(1px);
}
.am-picker-popup-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  z-index: 1000;
  transform: translateZ(1px);
}
.am-picker-popup-mask-hidden {
  display: none;
}
.am-picker-popup-header {
  background-image: linear-gradient(to bottom, #e7e7e7, #e7e7e7, transparent, transparent);
  background-position: bottom;
  background-size: 100% 1PX;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-picker-popup-header {
    border-bottom: none;
  }
  html:not([data-scale]) .am-picker-popup-header::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    transform-origin: 50% 100%;
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-picker-popup-header::after {
    transform: scaleY(0.33);
  }
}
.am-picker-popup-header .am-picker-popup-header-right {
  text-align: right;
}
.am-picker-popup-item {
  color: #108ee9;
  font-size: 17px;
  padding: 9px 15px;
  height: 42px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.am-picker-popup-item-active {
  background-color: #ddd;
}
.am-picker-popup-title {
  flex: 1 1;
  text-align: center;
  color: #000;
}
.am-picker-popup .am-picker-popup-close {
  display: none;
}
.am-picker-col {
  flex: 1 1;
}

.am-picker-col {
  display: block;
  position: relative;
  height: 238px;
  overflow: hidden;
  width: 100%;
}
.am-picker-col-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 102px 0;
}
.am-picker-col-item {
  touch-action: manipulation;
  text-align: center;
  font-size: 16px;
  height: 34px;
  line-height: 34px;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.am-picker-col-item-selected {
  font-size: 17px;
}
.am-picker-col-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)), linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
  background-position: top, bottom;
  background-size: 100% 102px;
  background-repeat: no-repeat;
}
.am-picker-col-indicator {
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  position: absolute;
  left: 0;
  top: 102px;
  z-index: 3;
  border-top: 1PX solid #ddd;
  border-bottom: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-picker-col-indicator {
    border-top: none;
  }
  html:not([data-scale]) .am-picker-col-indicator::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    transform-origin: 50% 50%;
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-picker-col-indicator::before {
    transform: scaleY(0.33);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-picker-col-indicator {
    border-bottom: none;
  }
  html:not([data-scale]) .am-picker-col-indicator::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    transform-origin: 50% 100%;
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-picker-col-indicator::after {
    transform: scaleY(0.33);
  }
}
.am-picker {
  display: flex;
  align-items: center;
}
.am-picker-item {
  flex: 1 1;
  text-align: center;
}

/* http://meyerweb.com/eric/tools/css/reset/
  v2.0-modified | 20110126
  License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%; */
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type='search'] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 12px;
  font-family: Roboto;
  background: #fff;
}
.iconfont {
  font-size: 24px;
  font-weight: 400;
  color: #4a4a4a;
}
.icon-down.active {
  transform: rotate(180deg);
}
.iconfont.icon-tips-default {
  opacity: 0.5;
  margin: 0 3px;
}
.iconfont.icon-convert.w {
  color: #fff;
  margin: 0 5px;
}
.navIcon,
.mlangIcon {
  display: none;
}
@media screen and (min-width: 760px) {
  .iconfont.icon-setting,
  .iconfont.icon-plus {
    font-size: 30px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 760px) {
  .container {
    padding-top: 0;
    background-size: contain;
  }
  .step {
    display: none;
  }
  .am-popover-arrow {
    background-color: #333;
  }
  .am-popover-inner-wrapper {
    color: #fff;
    background-color: #333;
  }
  .navIcon,
  .mlangIcon {
    display: block;
    width: 20px;
    height: 20px;
  }
  .iconTips {
    display: none;
  }
  .iconfont.icon-plus {
    color: #ff4311;
    margin: 0 3px;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: #333;
  font-size: 16px;
  line-height: 1.2;
}
h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
}
h4,
.schema-faq-question {
  font-size: 22px;
  text-align: left;
}
.description,
.wp-block-group__inner-container p,
.schema-faq-answer {
  color: #666;
  margin-top: 8px;
  margin-bottom: 32px;
  text-align: left;
}
a {
  color: #0094ff;
  text-decoration: none;
  margin-right: 30px;
  cursor: pointer;
}
.area0,
.area1,
.area3,
.area4,
.Box2,
.Box5,
.Box6 {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 50px;
}

.area0 {
  padding: 50px 0;
}
.area0 .Box0 .left,
.area0 .Box0 .right {
  width: 45%;
  border: 0.5px solid #979797;
}
.area0 .Box0 h3 {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background: linear-gradient(180deg, #fff8f5 0%, rgba(255, 242, 235, 0) 100%);
  border-bottom: 0.5px solid #979797;
  text-align: left;
  padding: 0 10px;
  box-sizing: border-box;
}
.area0 .Box0 .innerLink {
  display: flex;
  flex-wrap: wrap;
  padding: 0 25px;
  justify-content: space-between;
  list-style: disc;
  box-sizing: border-box;
}
.area0 .Box0 li {
  width: 40%;
  margin: 10px 0;
  text-align: left;
}
.area0 .Box0 a {
  width: 100%;
}

a:hover {
  color: #f50;
  font-weight: 700;
}
.Box2 {
  margin-bottom: 0;
}
.Box0,
.Box1,
.Box2,
.Box3,
.Box4,
.Box5,
.Box2 > .wp-block-group__inner-container {
  display: flex;
  justify-content: space-between;
}

.Box1 .stepBox,
.Box2 .stepBox {
  width: 28%;
}
.Box3 .stepBox,
.Box5 .stepBox {
  width: 40%;
  text-align: left;
}
.Box1 .stepBox .step {
  display: block;
  color: #ff4311;
  font-weight: 500;
  text-align: left;
}
.area1 .Box1 img,
.area5 .Box5 img {
  width: 100%;
  height: auto;
  margin-bottom: 18px;
}

.area2,
.area5 {
  background: #fafbfc;
  padding: 60px 0;
}
.area6,
.starBox {
  text-align: center;
}
.schema-faq {
  font-size: 16px;
  line-height: 1.2;
  padding: 60px 0;
  width: 100%;
  max-width: 1100px;
}

.Box2 .stepBox > p {
  margin: 30px 0 20px;
}
.Box2 .stepBox > p,
.Box2 img {
  width: 80px;
  height: 80px;
}
.area3,
.area6 {
  padding: 60px 0;
}
.area3 .Box3 .stepBox .postLink {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.postLink a {
  margin: 5px 10px;
}
.area4 .Box4 {
  flex-wrap: wrap;
}
.area4 .Box4 .stepBox {
  margin-bottom: 30px;
}
.schema-faq-section {
  margin-bottom: 50px;
}

.Box4 h4,
.schema-faq-question {
  padding-left: 8px;
  border-left: 4.5px solid #f50;
}
.area5 .Box5 h4 {
  margin: 15px 0;
}
.area5 .Box5 img {
  border-radius: 10px;
}
.area6 .Box6 img {
  width: 340px;
  height: 60px;
  margin-bottom: 20px;
}

.starBox img {
  margin-bottom: 20px;
  max-width: 260px;
  width: 100%;
  height: auto;
}
@media screen and (min-width: 800px) {
  .schema-faq {
    margin: 0 auto 40px;
  }
  .Box2 > .wp-block-group__inner-container {
    padding: 50px 0 80px;
  }
  .starBox {
    margin: 30px 0 80px;
  }
}
@media screen and (max-width: 760px) {
  body {
    font-size: 14px;
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  h4,
  .schema-faq-question {
    margin-top: 6px;
    font-size: 16px;
    line-height: 20px;
  }
  .area1,
  .area3,
  .area4,
  .Box2,
  .Box5,
  .Box6 {
    margin-bottom: 20px;
  }
  .area0 {
    margin-top: 50px;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .Box0 {
    flex-direction: column;
  }
  .Box0 .left,
  .Box0 .right {
    width: 100%;
    margin: 0 auto 20px;
  }
  .Box0 .innerLink {
    padding: 0 15px;
  }
  .area1 {
    margin: 0 auto;
  }
  h2,
  .area2,
  .area4,
  .area5,
  .schema-faq,
  .wp-block-group.Box2 {
    padding: 10px 15px;
    box-sizing: border-box;
  }
  .stepBox .wp-block-group__inner-container {
    text-align: left;
  }
  .area1,
  .area3,
  .area6 {
    background: #fafbfc;
    padding: 15px;
    box-sizing: border-box;
  }
  .area2,
  .area5 {
    background: #fff;
  }
  .Box1,
  .Box2,
  .Box3,
  .Box2 > .wp-block-group__inner-container {
    display: flex;
    flex-direction: column;
  }

  .Box3 > .stepBox:first-child {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .Box1 .stepBox,
  .Box2 .stepBox,
  .Box3 .stepBox {
    width: 100%;
    text-align: center;
  }
  .Box2 .stepBox > p {
    margin: 0;
  }
  .Box2 .stepBox > p,
  .Box2 img {
    width: 40px;
    height: 40px;
  }
  .area6 .Box6 img {
    width: 100px;
    height: 18px;
  }
  .Box1 img,
  .Box5 img {
    margin-bottom: 8px;
  }
  .Box3 .description {
    margin-bottom: 20px;
  }
}


body {
  display: flex;
  flex-direction: column;
  color: #333;
  font-size: 16px;
  line-height: 1.2;
}
h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
}
h4,
.schema-faq-question {
  font-size: 22px;
  text-align: left;
}
.description,
.wp-block-group__inner-container p,
.schema-faq-answer {
  color: #666;
  margin-top: 8px;
  margin-bottom: 32px;
  text-align: left;
}
a {
  color: #0094ff;
  text-decoration: none;
  margin-right: 30px;
  cursor: pointer;
}
.area0,
.area1,
.area3,
.area4,
.Box2,
.Box5,
.Box6 {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 50px;
}

.area0 {
  padding: 50px 0;
}
.area0 .Box0 .left,
.area0 .Box0 .right {
  width: 45%;
  border: 0.5px solid #979797;
}
.area0 .Box0 h3 {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background: linear-gradient(180deg, #fff8f5 0%, rgba(255, 242, 235, 0) 100%);
  border-bottom: 0.5px solid #979797;
  text-align: left;
  padding: 0 10px;
  box-sizing: border-box;
}
.area0 .Box0 .innerLink {
  display: flex;
  flex-wrap: wrap;
  padding: 0 25px;
  justify-content: space-between;
  list-style: disc;
  box-sizing: border-box;
}
.area0 .Box0 li {
  width: 40%;
  margin: 10px 0;
  text-align: left;
}
.area0 .Box0 a {
  width: 100%;
}

a:hover {
  color: #f50;
  font-weight: 700;
}
.Box2 {
  margin-bottom: 0;
}
.Box0,
.Box1,
.Box2,
.Box3,
.Box4,
.Box5,
.Box2 > .wp-block-group__inner-container {
  display: flex;
  justify-content: space-between;
}

.Box1 .stepBox,
.Box2 .stepBox {
  width: 28%;
}
.Box3 .stepBox,
.Box5 .stepBox {
  width: 40%;
  text-align: left;
}
.Box1 .stepBox .step {
  display: block;
  color: #ff4311;
  font-weight: 500;
  text-align: left;
}
.area1 .Box1 img,
.area5 .Box5 img {
  width: 100%;
  height: auto;
  margin-bottom: 18px;
}

.area2,
.area5 {
  background: #fafbfc;
  padding: 60px 0;
}
.area6,
.starBox {
  text-align: center;
}
.schema-faq {
  font-size: 16px;
  line-height: 1.2;
  padding: 60px 0;
  width: 100%;
  max-width: 1100px;
}

.Box2 .stepBox > p {
  margin: 30px 0 20px;
}
.Box2 .stepBox > p,
.Box2 img {
  width: 80px;
  height: 80px;
}
.area3,
.area6 {
  padding: 60px 0;
}
.area3 .Box3 .stepBox .postLink {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.postLink a {
  margin: 5px 10px;
}
.area4 .Box4 {
  flex-wrap: wrap;
}
.area4 .Box4 .stepBox {
  margin-bottom: 30px;
}
.schema-faq-section {
  margin-bottom: 50px;
}

.Box4 h4,
.schema-faq-question {
  padding-left: 8px;
  border-left: 4.5px solid #f50;
}
.area5 .Box5 h4 {
  margin: 15px 0;
}
.area5 .Box5 img {
  border-radius: 10px;
}
.area6 .Box6 img {
  width: 340px;
  height: 60px;
  margin-bottom: 20px;
}

.starBox img {
  margin-bottom: 20px;
  max-width: 260px;
  width: 100%;
  height: auto;
}
@media screen and (min-width: 800px) {
  .schema-faq {
    margin: 0 auto 40px;
  }
  .Box2 > .wp-block-group__inner-container {
    padding: 50px 0 80px;
  }
  .starBox {
    margin: 30px 0 80px;
  }
}
@media screen and (max-width: 760px) {
  body {
    font-size: 14px;
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  h4,
  .schema-faq-question {
    margin-top: 6px;
    font-size: 16px;
    line-height: 20px;
  }
  .area1,
  .area3,
  .area4,
  .Box2,
  .Box5,
  .Box6 {
    margin-bottom: 20px;
  }
  .area0 {
    margin-top: 50px;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .Box0 {
    flex-direction: column;
  }
  .Box0 .left,
  .Box0 .right {
    width: 100%;
    margin: 0 auto 20px;
  }
  .Box0 .innerLink {
    padding: 0 15px;
  }
  .area1 {
    margin: 0 auto;
  }
  h2,
  .area2,
  .area4,
  .area5,
  .schema-faq,
  .wp-block-group.Box2 {
    padding: 10px 15px;
    box-sizing: border-box;
  }
  .stepBox .wp-block-group__inner-container {
    text-align: left;
  }
  .area1,
  .area3,
  .area6 {
    background: #fafbfc;
    padding: 15px;
    box-sizing: border-box;
  }
  .area2,
  .area5 {
    background: #fff;
  }
  .Box1,
  .Box2,
  .Box3,
  .Box2 > .wp-block-group__inner-container {
    display: flex;
    flex-direction: column;
  }

  .Box3 > .stepBox:first-child {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .Box1 .stepBox,
  .Box2 .stepBox,
  .Box3 .stepBox {
    width: 100%;
    text-align: center;
  }
  .Box2 .stepBox > p {
    margin: 0;
  }
  .Box2 .stepBox > p,
  .Box2 img {
    width: 40px;
    height: 40px;
  }
  .area6 .Box6 img {
    width: 100px;
    height: 18px;
  }
  .Box1 img,
  .Box5 img {
    margin-bottom: 8px;
  }
  .Box3 .description {
    margin-bottom: 20px;
  }
}
